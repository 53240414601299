import React from 'react';
import {ContactDetailsSection,ContactDetailsLayout,ContactLayout,ContactIcon,
    ContactTextLayout,ContactHeading,ContactText
} from './contactdetails.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const ContactDetails  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        contactPageJson{
            ContactDetails{
                ContactCol{
                    ContactIcon
                    ContactHeading
                    ContactText
                }
            }
        }
    }
  `);

    return (
        <ContactDetailsSection id="ContactDetailsContainer">
            <Container>
                <ContactDetailsLayout>
                    <Row>
                        <Col md={4}>
                            <ContactLayout>
                                <ContactIcon src={JSONData.contactPageJson.ContactDetails.ContactCol[0].ContactIcon} alt="" />
                                <ContactTextLayout>
                                    <ContactHeading>
                                    {JSONData.contactPageJson.ContactDetails.ContactCol[0].ContactHeading}
                                    </ContactHeading>
                                    <ContactText>
                                    {JSONData.contactPageJson.ContactDetails.ContactCol[0].ContactText}
                                    </ContactText>
                                </ContactTextLayout>
                            </ContactLayout>

                        </Col>
                        <Col md={4}>
                            <ContactLayout>
                                <ContactIcon src={JSONData.contactPageJson.ContactDetails.ContactCol[1].ContactIcon} alt="" />
                                <ContactTextLayout>
                                    <ContactHeading>
                                    {JSONData.contactPageJson.ContactDetails.ContactCol[1].ContactHeading}
                                    </ContactHeading>
                                    <ContactText>
                                    {JSONData.contactPageJson.ContactDetails.ContactCol[1].ContactText}
                                    </ContactText>
                                </ContactTextLayout>
                            </ContactLayout>

                        </Col>
                        <Col md={4}>
                            <ContactLayout>
                                <ContactIcon src={JSONData.contactPageJson.ContactDetails.ContactCol[2].ContactIcon} alt="" />
                                <ContactTextLayout>
                                    <ContactHeading>
                                    {JSONData.contactPageJson.ContactDetails.ContactCol[2].ContactHeading}
                                    </ContactHeading>
                                    <ContactText>
                                    {JSONData.contactPageJson.ContactDetails.ContactCol[2].ContactText}
                                    </ContactText>
                                </ContactTextLayout>
                            </ContactLayout>
                        </Col>
                    </Row>
                </ContactDetailsLayout>
            </Container>
        </ContactDetailsSection>
    );
}
export default ContactDetails;